export const tiles = {
  "tiles" : [
    {
      "id": "red-one-1",
      "layout": "one-1",
      "color": "red",
      "startX": 385,
      "startY": 140,
    },
    {
      "id": "red-two-1",
      "layout": "two-1",
      "color": "red",
      "startX": 455,
      "startY": 140,
    },
    {
      "id": "red-three-1",
      "layout": "three-1",
      "color": "red",
      "startX": 560,
      "startY": 140,
    },
    {
      "id": "red-three-2",
      "layout": "three-2",
      "color": "red",
      "startX": 700,
      "startY": 140,
    },
    {
      "id": "red-four-1",
      "layout": "four-1",
      "color": "red",
      "startX": 1295,
      "startY": 210,
    },
    {
      "id": "red-four-2",
      "layout": "four-2",
      "color": "red",
      "startX": 1190,
      "startY": 210,
    },
    {
      "id": "red-four-3",
      "layout": "four-3",
      "color": "red",
      "startX": 910,
      "startY": 210,
    },
    {
      "id": "red-four-4",
      "layout": "four-4",
      "color": "red",
      "startX": 945,
      "startY": 140,
    },
    {
      "id": "red-four-5",
      "layout": "four-5",
      "color": "red",
      "startX": 1050,
      "startY": 210,
    },
    {
      "id": "red-five-1",
      "layout": "five-1",
      "color": "red",
      "startX": 1190,
      "startY": 140,
    },
    {
      "id": "red-five-2",
      "layout": "five-2",
      "color": "red",
      "startX": 1050,
      "startY": 350,
    },
    {
      "id": "red-five-3",
      "layout": "five-3",
      "color": "red",
      "startX": 560,
      "startY": 350,
    },
    {
      "id": "red-five-4",
      "layout": "five-4",
      "color": "red",
      "startX": 700,
      "startY": 350,
    },
    {
      "id": "red-five-5",
      "layout": "five-5",
      "color": "red",
      "startX": 805,
      "startY": 245,
    },
    {
      "id": "red-five-6",
      "layout": "five-6",
      "color": "red",
      "startX": 875,
      "startY": 385,
    },
    {
      "id": "red-five-7",
      "layout": "five-7",
      "color": "red",
      "startX": 455,
      "startY": 210,
    },
    {
      "id": "red-five-8",
      "layout": "five-8",
      "color": "red",
      "startX": 1155,
      "startY": 315,
    },
    {
      "id": "red-five-9",
      "layout": "five-9",
      "color": "red",
      "startX": 385,
      "startY": 385,
    },
    {
      "id": "red-five-10",
      "layout": "five-10",
      "color": "red",
      "startX": 1260,
      "startY": 350,
    },
    {
      "id": "red-five-11",
      "layout": "five-11",
      "color": "red",
      "startX": 385,
      "startY": 245,
    },
    {
      "id": "red-five-12",
      "layout": "five-12",
      "color": "red",
      "startX": 595,
      "startY": 210,
    },
    {
      "id": "green-one-1",
      "layout": "one-1",
      "color": "green",
      "startX": 35,
      "startY": 525,
    },
    {
      "id": "green-two-1",
      "layout": "two-1",
      "color": "green",
      "startX": 105,
      "startY": 525,
    },
    {
      "id": "green-three-1",
      "layout": "three-1",
      "color": "green",
      "startX": 245,
      "startY": 525,
    },
    {
      "id": "green-three-2",
      "layout": "three-2",
      "color": "green",
      "startX": 420,
      "startY": 490,
    },
    {
      "id": "green-four-1",
      "layout": "four-1",
      "color": "green",
      "startX": 385,
      "startY": 805,
    },
    {
      "id": "green-four-2",
      "layout": "four-2",
      "color": "green",
      "startX": 280,
      "startY": 805,
    },
    {
      "id": "green-four-3",
      "layout": "four-3",
      "color": "green",
      "startX": 35,
      "startY": 805,
    },
    {
      "id": "green-four-4",
      "layout": "four-4",
      "color": "green",
      "startX": 35,
      "startY": 595,
    },
    {
      "id": "green-four-5",
      "layout": "four-5",
      "color": "green",
      "startX": 140,
      "startY": 805,
    },
    {
      "id": "green-five-1",
      "layout": "five-1",
      "color": "green",
      "startX": 210,
      "startY": 595,
    },
    {
      "id": "green-five-2",
      "layout": "five-2",
      "color": "green",
      "startX": 210,
      "startY": 1050,
    },
    {
      "id": "green-five-3",
      "layout": "five-3",
      "color": "green",
      "startX": 210,
      "startY": 910,
    },
    {
      "id": "green-five-4",
      "layout": "five-4",
      "color": "green",
      "startX": 385,
      "startY": 945,
    },
    {
      "id": "green-five-5",
      "layout": "five-5",
      "color": "green",
      "startX": 420,
      "startY": 630,
    },
    {
      "id": "green-five-6",
      "layout": "five-6",
      "color": "green",
      "startX": 35,
      "startY": 1050,
    },
    {
      "id": "green-five-7",
      "layout": "five-7",
      "color": "green",
      "startX": 140,
      "startY": 665,
    },
    {
      "id": "green-five-8",
      "layout": "five-8",
      "color": "green",
      "startX": 315,
      "startY": 1050,
    },
    {
      "id": "green-five-9",
      "layout": "five-9",
      "color": "green",
      "startX": 35,
      "startY": 945,
    },
    {
      "id": "green-five-10",
      "layout": "five-10",
      "color": "green",
      "startX": 420,
      "startY": 1050,
    },
    {
      "id": "green-five-11",
      "layout": "five-11",
      "color": "green",
      "startX": 35,
      "startY": 665,
    },
    {
      "id": "green-five-12",
      "layout": "five-12",
      "color": "green",
      "startX": 280,
      "startY": 665,
    },
    {
      "id": "blue-one-1",
      "layout": "one-1",
      "color": "blue",
      "startX": 1260,
      "startY": 525,
    },
    {
      "id": "blue-two-1",
      "layout": "two-1",
      "color": "blue",
      "startX": 1330,
      "startY": 525,
    },
    {
      "id": "blue-three-1",
      "layout": "three-1",
      "color": "blue",
      "startX": 1470,
      "startY": 525,
    },
    {
      "id": "blue-three-2",
      "layout": "three-2",
      "color": "blue",
      "startX": 1645,
      "startY": 490,
    },
    {
      "id": "blue-four-1",
      "layout": "four-1",
      "color": "blue",
      "startX": 1610,
      "startY": 805,
    },
    {
      "id": "blue-four-2",
      "layout": "four-2",
      "color": "blue",
      "startX": 1505,
      "startY": 805,
    },
    {
      "id": "blue-four-3",
      "layout": "four-3",
      "color": "blue",
      "startX": 1260,
      "startY": 805,
    },
    {
      "id": "blue-four-4",
      "layout": "four-4",
      "color": "blue",
      "startX": 1260,
      "startY": 595,
    },
    {
      "id": "blue-four-5",
      "layout": "four-5",
      "color": "blue",
      "startX": 1365,
      "startY": 805,
    },
    {
      "id": "blue-five-1",
      "layout": "five-1",
      "color": "blue",
      "startX": 1435,
      "startY": 595,
    },
    {
      "id": "blue-five-2",
      "layout": "five-2",
      "color": "blue",
      "startX": 1435,
      "startY": 1050,
    },
    {
      "id": "blue-five-3",
      "layout": "five-3",
      "color": "blue",
      "startX": 1435,
      "startY": 910,
    },
    {
      "id": "blue-five-4",
      "layout": "five-4",
      "color": "blue",
      "startX": 1610,
      "startY": 945,
    },
    {
      "id": "blue-five-5",
      "layout": "five-5",
      "color": "blue",
      "startX": 1645,
      "startY": 630,
    },
    {
      "id": "blue-five-6",
      "layout": "five-6",
      "color": "blue",
      "startX": 1260,
      "startY": 1050,
    },
    {
      "id": "blue-five-7",
      "layout": "five-7",
      "color": "blue",
      "startX": 1365,
      "startY": 665,
    },
    {
      "id": "blue-five-8",
      "layout": "five-8",
      "color": "blue",
      "startX": 1540,
      "startY": 1050,
    },
    {
      "id": "blue-five-9",
      "layout": "five-9",
      "color": "blue",
      "startX": 1260,
      "startY": 945,
    },
    {
      "id": "blue-five-10",
      "layout": "five-10",
      "color": "blue",
      "startX": 1645,
      "startY": 1050,
    },
    {
      "id": "blue-five-11",
      "layout": "five-11",
      "color": "blue",
      "startX": 1260,
      "startY": 665,
    },
    {
      "id": "blue-five-12",
      "layout": "five-12",
      "color": "blue",
      "startX": 1505,
      "startY": 665,
    },
    {
      "id": "yellow-one-1",
      "layout": "one-1",
      "color": "yellow",
      "startX": 385,
      "startY": 1155,
    },
    {
      "id": "yellow-two-1",
      "layout": "two-1",
      "color": "yellow",
      "startX": 455,
      "startY": 1155,
    },
    {
      "id": "yellow-three-1",
      "layout": "three-1",
      "color": "yellow",
      "startX": 560,
      "startY": 1155,
    },
    {
      "id": "yellow-three-2",
      "layout": "three-2",
      "color": "yellow",
      "startX": 700,
      "startY": 1155,
    },
    {
      "id": "yellow-four-1",
      "layout": "four-1",
      "color": "yellow",
      "startX": 1295,
      "startY": 1225,
    },
    {
      "id": "yellow-four-2",
      "layout": "four-2",
      "color": "yellow",
      "startX": 1190,
      "startY": 1225,
    },
    {
      "id": "yellow-four-3",
      "layout": "four-3",
      "color": "yellow",
      "startX": 910,
      "startY": 1225,
    },
    {
      "id": "yellow-four-4",
      "layout": "four-4",
      "color": "yellow",
      "startX": 945,
      "startY": 1155,
    },
    {
      "id": "yellow-four-5",
      "layout": "four-5",
      "color": "yellow",
      "startX": 1050,
      "startY": 1225,
    },
    {
      "id": "yellow-five-1",
      "layout": "five-1",
      "color": "yellow",
      "startX": 1190,
      "startY": 1155,
    },
    {
      "id": "yellow-five-2",
      "layout": "five-2",
      "color": "yellow",
      "startX": 1050,
      "startY": 1365,
    },
    {
      "id": "yellow-five-3",
      "layout": "five-3",
      "color": "yellow",
      "startX": 560,
      "startY": 1365,
    },
    {
      "id": "yellow-five-4",
      "layout": "five-4",
      "color": "yellow",
      "startX": 700,
      "startY": 1365,
    },
    {
      "id": "yellow-five-5",
      "layout": "five-5",
      "color": "yellow",
      "startX": 805,
      "startY": 1260,
    },
    {
      "id": "yellow-five-6",
      "layout": "five-6",
      "color": "yellow",
      "startX": 875,
      "startY": 1400,
    },
    {
      "id": "yellow-five-7",
      "layout": "five-7",
      "color": "yellow",
      "startX": 455,
      "startY": 1225,
    },
    {
      "id": "yellow-five-8",
      "layout": "five-8",
      "color": "yellow",
      "startX": 1155,
      "startY": 1330,
    },
    {
      "id": "yellow-five-9",
      "layout": "five-9",
      "color": "yellow",
      "startX": 385,
      "startY": 1400,
    },
    {
      "id": "yellow-five-10",
      "layout": "five-10",
      "color": "yellow",
      "startX": 1260,
      "startY": 1365,
    },
    {
      "id": "yellow-five-11",
      "layout": "five-11",
      "color": "yellow",
      "startX": 385,
      "startY": 1260,
    },
    {
      "id": "yellow-five-12",
      "layout": "five-12",
      "color": "yellow",
      "startX": 595,
      "startY": 1225,
    },
  ]
}